/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
import $ from 'jquery'
;(function () {
	'use strict'

	if ($('#newsletter__form-id').length) {
		$('.newsletter-box').remove()
		$('.nlb-heighter').remove()
	}

	var formID = $('#newsletter__form-id')
	var formContainer = $('[data-role="newsletter-container"]')
	var formSucessContainer = $('[data-role="newsletter-sucess"]')
	var emailInput = $('[data-role="email-input"]')
	var toggleText = $('[data-action="toggle-text"]')
	var textToToggle = $('[data-role="text-to-toggle"]')
	var countErrors = 0

	toggleText.on('click', function (e) {
		e.preventDefault()
		textToToggle.toggleClass('_show')
		$(e.target).hide()
		$('.toggle-text-ellipsis').hide()
		if (textToToggle.hasClass('_show')) {
			textToToggle.css({ 'max-height': textToToggle.prop('scrollHeight'), display: 'inline' })
		} else {
			textToToggle.css({ 'max-height': 0, display: 'block' })
		}
	})

	function validateEmail(email) {
		let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
		return regex.test(email)
	}

	function displaySuccessMessage() {
		formSucessContainer.show()
	}

	function formValidations(hasErrors) {
		if (emailInput.val() == '' || validateEmail(emailInput.val()) == false) {
			emailInput.addClass('has-error')
			hasErrors += 1
		} else {
			emailInput.removeClass('has-error')
		}

		return hasErrors
	}

	/* function convertFormToJSON(form) {
		return $(form)
			.serializeArray()
		  	.reduce(function (json, { name, value }) {
				json[name] = value;
				return json;
			}, {});
	} */

	formID.on('submit', function (event) {
		if (formValidations(countErrors) != 0) {
			return false
		} else {
			formValidations(countErrors)
			// displaySuccessMessage();
			/* var dataString = $(this).serialize(); //convertFormToJSON($(this));
			console.log(dataString);
			$.ajax({
				type: "POST",
				crossDomain: true,
				dataType: 'jsonp',
				jsonp : false,
				url: "https://web.inxmail.com/nvbw/subscription/servlet", // check with Feng
				data: dataString,
				success: function (data) {
					console.log(data);
					console.log('sucess');
					displaySuccessMessage();
				},
				error: function(data) {
					console.log( "error: ", data );
				} */
		}
	})
})()
